<template>
  <div>
    <div class="key-visual">
      <div class="mask">
        <div class="contents">
          <div class="txt">
            이모션글로벌은<br />
            프로젝트에<br />
            맞춤 컨설팅을 제공합니다.
          </div>
        </div>
        <div class="bg"></div>
      </div>
    </div>
    <div class="contents">
      <h3 class="cont-title">
        이모션글로벌에서는<br />
        다음과 같은 컨설팅을 수행합니다.
      </h3>
      <div class="cont-inner" :class="{open: isOpen}">
        <ul class="cont-list">
          <li v-for="(item, index) in contList" :key="index">
            <strong class="tit montserrat">{{ item.title_1 }}</strong>
            <span class="sub">{{ item.title_2 }}</span>
            <p class="txt">
              {{ item.txt }}
            </p>
          </li>
        </ul>
        <button class="btn-open" type="button" @click="toggle">
          {{ isOpen ? "접기" : "더 보기" }}
        </button>
      </div>
      <div class="bg-img">
        <img src="@/assets/images/consulting/list_bg.png" alt="배경이미지" />
      </div>
      <section class="section section-1">
        <span class="section-txt">
          이모션 컨설팅 방법론 “퍼즐”
        </span>
        <h3 class="section-tit">
          프로젝트 목표에 맞는 솔루션을 제공하는<br />
          이모션글로벌만의 유연한 전략 컨설팅 방법론을<br />
          우리는 <span>퍼즐</span>
          <img class="puzzle-img" src="@/assets/images/consulting/puzzle.png" alt="퍼즐" />
          이라고 부릅니다.
        </h3>
        <div class="section-img lottie-cont lottie01">
          <lottie-consulting01 />
        </div>
      </section>
      <section class="section section-2">
        <span class="section-txt">
          컨실팅 프로세스 “퍼즐보드”
        </span>
        <h3 class="section-tit">
          프로젝트에 적합한<br />
          최적의 컨설팅 프로세스를<br />
          우리는 <span>퍼즐보드</span>
          <img class="puzzle-img" src="@/assets/images/consulting/puzzle_01.png" alt="퍼즐보드" />
          라고 부릅니다.
        </h3>
        <div class="section-img">
          <img src="@/assets/images/consulting/puzzle_bg_01.png" alt="퍼즐보드" />
        </div>
      </section>
      <section class="section section-3">
        <span class="section-txt">
          연구방법론 “퍼즐피스”
        </span>
        <h3 class="section-tit">
          이모션글로벌이 수행 가능한 여러 연구방법론을<br />
          우리는 <span>퍼즐피스</span>
          <img class="puzzle-img" src="@/assets/images/consulting/puzzle_02.png" alt="퍼즐피스" />
          라고 부릅니다.
        </h3>
        <div class="section-img" :class="{open: isOpen2}">
          <button class="btn-open" type="button" @click="toggle2">
            {{ isOpen2 ? "접기" : "더 보기" }}
          </button>
          <img
            src="@/assets/images/consulting/puzzle_bg_02.png"
            alt="Co-creation Workshop 코크리에이션 워크샵 Desk Research 데스크 리서치 Focus Group Interview 표적 집단 면접법 In-depth interview 심층 인터뷰 Contextual Inquiry Service Safari Expert Interview Usability Test 사용자 리서치 정황조사 서비스 사파리 전문가 인터뷰 사용성 테스트 Persona 퍼소나 Journey map 고객 여정 지도 Log Analytics 로그 분석 Voice of Customer 고객의 소리 Eyetracking 시선추적 Survey 설문조사 Voting 투표하기 Affinity Diagram 친화도법 Card Sorting 서비스 정보 그룹화 Mind Mapping 정보 시각화 지도 Heuristics 전문가 UX 평가 Positioning Map 지각도 Mental Model 멘탈모델 Expert Solutions 전문가 솔루션 Service Prototyping 서비스 프로토타이핑 UX Flow 사용자 흐름 Flow Chart A/B Testing 순서도/흐름도 집단 비교 테스트"
          />
          <button class="btn-detail" type="button" @click="openModal">
            퍼즐피스 자세히보기
            <img class="puzzle-img" src="@/assets/images/consulting/ic_plus.svg" alt="자세히보기" />
          </button>
        </div>
      </section>
      <section class="section section-4">
        <h3 class="section-tit">
          프로젝트 전반에 걸쳐<br />
          AI기반의 데이터 분석을 활용하여<br />
          업무 생산성을 높입니다.
        </h3>
        <p class="section-desc">
          컨설팅 과정에서 AI를 적극 활용하여 자원을 효율적으로 관리하고, 시간과 비용을 절감하여<br />
          전반적인 생산성을 높이고보다 깊이 있는 분석과 가치 있는 결과를 제공합니다.
        </p>
        <div class="section-img lottie-cont lottie01">
          <lottie-consulting02 />
        </div>
      </section>
      <section class="section section-5">
        <h3 class="section-tit">프로젝트의 전 과정을 체계적이고<br />효율적으로 진행합니다.</h3>
        <p class="section-desc">
          각 단계를 신중하게 설계하여 클라이언트가 기대 이상의 가치를<br />
          실현할 수 있도록 집중합니다.
        </p>
        <div class="section-img">
          <img src="@/assets/images/consulting/puzzle_bg_03.png" alt="프로젝트 과정" />
        </div>
      </section>
      <section class="section section-6" :class="{open: isOpen2}">
        <h3 class="section-tit">
          검증된 전문성과 노하우를 바탕으로, <br />성공적으로 완수한 프로젝트들을 소개합니다.
        </h3>
      </section>
    </div>
    <section class="swiper-section" ref="swiperSection">
      <swiper class="swiper-container" :options="swiperOptions">
        <swiper-slide
          v-for="(item, index) in swiperData"
          :key="index"
          :style="`background: url(${item.bgImg}) center / cover no-repeat`"
        >
          <div class="inner">
            <div
              class="logo swiper-text"
              :style="`background: url(${item.logoImg}) 0 0 / cover no-repeat`"
            >
              {{ item.title }}
            </div>
            <div class="right">
              <h3 class="title swiper-text">
                <strong v-html="item.title_1"></strong>
                {{ item.title_2 }}
              </h3>
              <p class="sm-tit swiper-text">
                {{ item.sm_tit }}
              </p>
              <p class="sm-txt swiper-text">
                {{ item.sm_txt }}
              </p>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-button-next">
        <img src="@/assets/images/consulting/ic_nav.svg" alt="swiper_arrow" />
      </div>
      <div class="swiper-button-prev">
        <img src="@/assets/images/consulting/ic_nav.svg" alt="swiper_arrow" />
      </div>
    </section>
    <portal to="modal-component" tag="div" name="modal-component">
      <modalComponent :isOpen="isModalOpen" @close="closeModal">
        <template v-slot:title> 26개의 퍼즐 피스 <span>연구방법론</span> </template>
        <template v-slot>
          <img src="@/assets/images/consulting/popup-img.png" alt="팝업 이미지"
        /></template>
      </modalComponent>
    </portal>
  </div>
</template>
<script>
  import {Swiper, SwiperSlide} from "vue-awesome-swiper";
  import "swiper/css/swiper.css";
  import {Power0} from "gsap";
  import {mapGetters} from "vuex";
  import LottieConsulting01 from "@/components/lottie/consulting01.vue";
  import LottieConsulting02 from "@/components/lottie/consulting02.vue";
  import {TweenMax, Power2} from "gsap";
  import ModalComponent from "@/components/global/modal-component.vue";

  export default {
    name: "consulting",
    components: {ModalComponent, LottieConsulting01, LottieConsulting02, Swiper, SwiperSlide},
    metaInfo() {
      return {
        title: "Consulting",
        titleTemplate: "%s | e · motion",
        meta: [
          {
            property: "og:title",
            content: "Consulting | e · motion",
            vmid: "og:title"
          },
          {
            property: "og:url",
            content: "https://www.emotion.co.kr/consulting/",
            vmid: "og:url"
          }
        ]
      };
    },
    data() {
      return {
        isOpen: false,
        scrollPosition: 0,
        isOpen2: false,
        scrollPosition2: 0,
        testData: false,
        isModalOpen: false,
        contList: [
          {
            title_1: "UX Consulting",
            title_2: "사용자 경험 컨설팅",
            txt:
              "사용자가 서비스를 더 쉽고 편리하며 만족스러운 경험을 제공할 수 있도록 설계와 개선 방향을 제안합니다."
          },
          {
            title_1: "BX Consulting",
            title_2: "사용자 경험 컨설팅",
            txt:
              "브랜드가 고객과 만나는 모든 접점에서 일관되고긍정적인 이미지를 전달할 수 있도록 돕습니다."
          },
          {
            title_1: "CX Consulting",
            title_2: "고객 경험 컨설팅",
            txt:
              "고객이 서비스를 처음 접할 때부터의 모든 단계에서 만족스럽고 긍정적인 경험을 하도록 개선합니다."
          },
          {
            title_1: "Visual Prototyping",
            title_2: "시각화 (프로토타이핑)",
            txt:
              "컨설팅 결과물을 시각화(디자인 + 프로토타이핑) 하여 눈에 보이는 결과물이 나오는 컨설팅을 진행합니다."
          },
          {
            title_1: "UX Writing",
            title_2: "사용자 경험에 맞춘 글쓰기",
            txt:
              "사용자가 디지털 제품이나 서비스를 쉽게 이해하고 원활하게 사용할 수 있도록 글의 스타일과 체계를 수립합니다."
          },
          {
            title_1: "BX Writing",
            title_2: "브랜드 경험에 맞춘 글쓰기",
            txt:
              "브랜드 인상과 이미지를 결정짓는 보이스 톤 연구를 기반으로, 브랜드에 적합한 글의 스타일과 원칙을 수립합니다."
          },
          {
            title_1: "Data Analytics",
            title_2: "데이터 분석",
            txt:
              "데이터를 수집, 처리, 분석하여 의미 있는 인사이트를 도출하고 의사결정을 돕는 근거 있는 컨설팅을 진행합니다."
          },
          {
            title_1: "Design System",
            title_2: "디자인 시스템",
            txt:
              "디자인 방향성을 수립하고, 체계화된 디자인 시스템과 GUI를 통해 완성도 높은 결과물을 만듭니다."
          },
          {
            title_1: "UX Research",
            title_2: "사용자 경험 분석",
            txt:
              "다양한 방법론을 활용하여 데이터를 수집, 분석하고 이를 바탕으로 사용자 경험을 연구합니다."
          },
          {
            title_1: "Accessibility Consulting",
            title_2: "접근성 컨설팅",
            txt:
              "정보취약계층의 접근성과 사용성을 평가하고 개선 방안을 제공하는 전문 컨설팅을 진행합니다."
          },
          {
            title_1: "UX/UI Design",
            title_2: "사용자 경험/사용자 인터페이스 디자인",
            txt:
              "근거 있는 컨설팅이 진행된 후, UX/UI 설계와 디자인을 통해 최종 결과를 구체화합니다."
          },
          {
            title_1: "UX Education",
            title_2: "사용자 경험 관련 교육",
            txt:
              "다양한 경험이 있는 전문가가 UX전문 역량을 기반으로 필요한 지식과 노하우를 공유하는 세미나를 진행합니다."
          },
          {
            title_1: "Operations",
            title_2: "운영",
            txt:
              "서비스 핵심 지표에 대한 지속적인 점검을 통해 반복적인 개선을 진행하여, 서비스의 운영과 성장을 지원합니다."
          }
        ],
        swiperData: [
          {
            title: "동아출판",
            bgImg: require("@/assets/images/consulting/swiper_00.jpg"),
            logoImg: require("@/assets/images/consulting/swiper_logo_00.png"),
            title_1: "AI 디지털 교과서",
            title_2: "BX/UX 컨설팅&구축",
            sm_tit: "BXUX Consulting & UXUI Design",
            sm_txt:
              "인공지능과 디지털 기술의 발전을 반영해 서책 교과서의 한계를 넘어서는 AI 기반 디지털 교과서, 그리고 '모두를 위한 맞춤형 교육'을 목표로, 차별화된 브랜드 경험을 제공하기 위한 BX/UX 컨설팅 및 디자인을 진행하였습니다."
          },
          {
            title: "SK telecom",
            bgImg: require("@/assets/images/consulting/swiper_01.jpg"),
            logoImg: require("@/assets/images/consulting/swiper_logo_01.png"),
            title_1: "SK텔레콤 T다이렉트샵",
            title_2: "UX/UI 컨설팅",
            sm_tit: "UXUI Consulting",
            sm_txt:
              "SK텔레콤의 공식 온라인샵인 T다이렉트샵의 고객의 사용성 및 서비스 분석을 통해 현시대 사용성에 맞는 합리적 UX를 제공하고 T다이렉트 샵만의 차별화된 브랜드 경험 제공을 위한 개선 방향을 도출하였습니다."
          },
          {
            title: "신한은행",
            bgImg: require("@/assets/images/consulting/swiper_02.png"),
            logoImg: require("@/assets/images/consulting/swiper_logo_02.png"),
            title_1: "신한 O2O 서비스",
            title_2: "BX/UX 컨설팅",
            sm_tit: "BXUX Consulting",
            sm_txt:
              "신한은행에서 런칭하는 배달 서비스에 대한 여정 중심의 Task 수행/만족도 평가를 통해 사용성 이슈를 발굴하고, 신규 서비스 아이디어 발굴 및 BX/UX측면의 방향성을 제시하였습니다."
          },
          {
            title: "연합인포맥스",
            bgImg: require("@/assets/images/consulting/swiper_03.jpg"),
            logoImg: require("@/assets/images/consulting/swiper_logo_03.png"),
            title_1: "연합인포맥스 I-CAMS ",
            title_2: "BXUX Consulting",
            sm_tit: "Digital Transformation & Cloud Services",
            sm_txt:
              "자산운용사에서 사용하는 자산운용 솔루션 서비스에 대한 BX/UX 컨설팅 프로젝트 금융 서비스 중 가장 높은 난이도의 용어와 프로세스를 이해하고 사용자의 관점으로 서비스의 플로우와 UI를 고도화하였습니다. 자산운용 서비스의 경쟁없는 1위의 포지션에 비해 빈약한 브랜드 이미지와 경험을 고도화하여 고객의 긍정적 이미지를 확보하였습니다."
          },
          {
            title: "이베스트 투자증권 통합 MTS",
            bgImg: require("@/assets/images/consulting/swiper_04.jpg"),
            logoImg: require("@/assets/images/consulting/swiper_logo_04.png"),
            title_1: "이베스트 투자증권 통합 MTS",
            title_2: "UX/UI 컨설팅&구축",
            sm_tit: "UXUI Consulting & UXUI Design",
            sm_txt:
              "이베스트 온은 적응하는 MTS라는 목표 아래 주식거래 편의성을 극대화한 모바일 주식거래 경험을 제공합니다. 이베스트 온은 사용자에 따라 간편 모드 등 다양한 옵션을 선태할 수 있고, 중요한 정보를 즉각적으로 인지하여 쉽고 빠르게 모바일 상에서 주식거래를 할 수 있는 환경을 제공합니다."
          },
          {
            title: "현대백화점 그룹 통합 멤버십",
            bgImg: require("@/assets/images/consulting/swiper_05.jpg"),
            logoImg: require("@/assets/images/consulting/swiper_logo_05.png"),
            title_1: "현대백화점 그룹 통합 멤버십 <br/>H.Point APP",
            title_2: "UX/UI 컨설팅&구축",
            sm_tit: "UXUI Consulting & UXUI Design",
            sm_txt:
              "현대백화점 그룹 통합멤버십 H.Point은 새로운 포인트 소비 습관을 위해 통합 멤버십 서비스 H.Point를 전면 리뉴얼 개편하였습니다. 고객 편의성 강화를 위해 ‘고객인사이트 도출 컨설팅’을 선행하여 대고객 소통을 강화하는 APP을 설계하였습니다."
          },
          {
            title: "이터널저니 온라인 커머스 플랫폼",
            bgImg: require("@/assets/images/consulting/swiper_06.jpg"),
            logoImg: require("@/assets/images/consulting/swiper_logo_06.png"),
            title_1: "이터널저니 온라인 커머스 플랫폼",
            title_2: "BX/UX 컨설팅",
            sm_tit: "BXUX Consulting & UXUI Design",
            sm_txt:
              "아난티의 오프라인 샵인 이터널저니의 온라인 확장을 위해 컨설팅을 진행하며 현시점의 니즈를 분석하여 BX/UX의 방향성과 컨셉을 도출하였습니다. 도출된 컨셉의 시각화를 통해 아난티의 정체성을 담아내고, 반응형 웹사이트로 설계하여 다양한 디바이스에서 최적화된 서비스를 제공할 수 있도록 하였습니다."
          },
          {
            title: "푸마 공식 온라인몰",
            bgImg: require("@/assets/images/consulting/swiper_07.jpg"),
            logoImg: require("@/assets/images/consulting/swiper_logo_07.png"),
            title_1: "푸마 공식 온라인몰",
            title_2: "UX/UI 컨설팅",
            sm_tit: "UXUI Consulting",
            sm_txt:
              "푸마 공식 온라인몰의 UX/UI 품질 진단과 문제점 파악을 통해 UX, UI, Flow, BX측면의 사용성 개선 방안을 도출하였습니다."
          },
          {
            title: "NIKE",
            bgImg: require("@/assets/images/consulting/swiper_08.jpg"),
            logoImg: require("@/assets/images/consulting/swiper_logo_08.png"),
            title_1: "NIKE",
            title_2: "UX/UI 컨설팅",
            sm_tit: "UXUI Consulting",
            sm_txt:
              "NIKE.COM의 UX/UI 개선을 통한 브랜드 가치를 강화한 프로젝트로서 UX/UI 컨설팅을 통하여 온라인 쇼핑 경험 만족도 향상 및 매출 확대를 목표로 진행되었으며, 전략적 사용자 경험 분석을 통해 나이키만의 강점을 강화할 수 있는 체계적이고 구조적인 서비스 개선을 진행하였습니다."
          }
        ],
        swiperOptions: {
          loop: true,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          }
        },
        isExpanded: false
      };
    },
    mounted() {
      this.initSwiperPosition();
      window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
    },
    provide() {
      const my = {};
      Object.defineProperty(my, "testData", {
        enumerable: true,
        get: () => this.testData
      });
      return {my};
    },
    computed: {
      ...mapGetters(["GE_CONT_HEIGHT"])
    },
    watch: {
      GE_CONT_HEIGHT() {
        if (this.$el.offsetParent) {
          this.init();
        }
      }
    },
    methods: {
      init() {
        this.scene1?.destroy(true);
        const keyVisual = document.querySelector(".key-visual");
        const tween = TweenMax.to(keyVisual.querySelector(".bg"), 1, {
          y: keyVisual.clientHeight,
          ease: Power0.easeInOut
        });
        const tween2 = TweenMax.to(keyVisual.querySelector(".txt"), 1, {
          y: keyVisual.clientHeight + keyVisual.querySelector(".txt").clientHeight,
          ease: Power0.easeInOut
        });
        this.scene1 = this.$scrollmagic
          .scene({
            triggerElement: keyVisual,
            triggerHook: 0,
            duration: keyVisual.clientHeight * 2
          })
          .setTween([tween, tween2]);
        this.$scrollmagic.addScene(this.scene1);
      },
      initSwiperPosition() {
        const swiperSection = this.$refs.swiperSection;
        if (swiperSection) {
          TweenMax.set(swiperSection, {
            width: "100%",
            height: "100vh",
            position: "relative",
            margin: "0 auto",
            clipPath: "inset(35% 15% 35% 15%)"
          });
        }
      },
      handleScroll() {
        const scrollY = window.scrollY || window.pageYOffset;
        const swiperSection = this.$refs.swiperSection;
        if (swiperSection) {
          const sectionTop = swiperSection.offsetTop;
          const swiperHeight = window.innerHeight;
          const startScroll = sectionTop - swiperHeight;
          const progress = Math.min(1, Math.max(0, (scrollY - startScroll) / swiperHeight));
          const inset = 35 - 35 * progress;

          TweenMax.to(swiperSection, 1, {
            clipPath: `inset(${inset}% ${inset / 2}% ${inset}% ${inset / 2}%)`,
            ease: Power2.easeOut,
            onUpdate: () => {
              // console.log("progress", progress);
              if (progress >= 0.5) {
                this.isExpanded = true;
                document.querySelectorAll(".swiper-text").forEach((text, index) => {
                  TweenMax.to(text, 0.8, {
                    opacity: 1,
                    y: 0,
                    delay: index * 0.1,
                    ease: Power2.easeOut
                  });
                });
              } else if (progress <= 0.5) {
                this.isExpanded = false;
                document.querySelectorAll(".swiper-text").forEach(text => {
                  TweenMax.to(text, 0.2, {
                    opacity: 0,
                    y: 20,
                    ease: Power2.easeOut
                  });
                });
              }
            }
          });
        }
      },
      openModal() {
        this.scrollTop = window.scrollY;
        this.isModalOpen = true;

        // 스크롤 막기 및 위치 고정
        document.body.style.position = "fixed";
        document.body.style.top = `-${this.scrollTop}px`;
        document.body.style.width = "100%";
      },
      closeModal() {
        this.isModalOpen = false;

        // 스크롤 복원
        document.body.style.position = "";
        document.body.style.top = "";
        document.body.style.width = "";
        window.scrollTo(0, this.scrollTop);
      },
      toggle() {
        if (this.isOpen) {
          window.scrollTo({
            top: this.scrollPosition
          });
        } else {
          this.scrollPosition = window.scrollY;
        }
        this.isOpen = !this.isOpen;
      },
      toggle2() {
        if (this.isOpen2) {
          window.scrollTo({
            top: this.scrollPosition2
          });
        } else {
          this.scrollPosition2 = window.scrollY;
        }
        this.isOpen2 = !this.isOpen2;
      }
    }
  };
</script>
<style scoped>
  .key-visual {
    position: relative;
    min-height: 1000px;
    height: 100vh;
    min-width: 1264px;
    .mask {
      height: 100vh;
      position: relative;
      overflow: hidden;
    }
    .contents {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      justify-content: center;
      align-items: flex-start;
      height: 100vh;
      min-height: 800px;
    }
    .bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: url(../../assets/images/consulting/img_consulting_main.webp) center center / cover
        no-repeat;
    }
    .txt {
      margin-top: 120px;
      height: 540px;
      padding-left: 20px;
      left: 0;
      top: 0;
      font-weight: 700;
      font-size: 70px;
      color: #fff;
      line-height: 100px;
      letter-spacing: -0.03em;
    }
  }
  .cont-title {
    margin-top: 180px;
    font-weight: 700;
    font-size: 48px;
    line-height: 74px;
    color: #000;
    letter-spacing: -0.05em;
  }
  .btn-open {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 14px;
    color: #101010;
    font-family: "Noto Sans KR", sans-serif;
    font-size: 18px;
    line-height: 150%;
    z-index: 2;
    background: none;
    padding: 0;
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: -20px;
      transform: translateY(-50%);
      width: 15px;
      height: 8px;
      background: url(../../assets/images/select_arrow.png) no-repeat center/cover;
    }
  }
  .cont-inner {
    margin-top: 100px;
    position: relative;
    height: 426px;
    overflow: hidden;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 140px;
      z-index: 1;
      background: url(../../assets/images/consulting/gradient.png) center center/cover no-repeat;
    }
    &.open {
      padding-bottom: 47px;
      .btn-open {
        &:after {
          transform: translateY(-50%) rotate(180deg);
        }
      }
      &:before {
        display: none;
      }
      height: auto;
    }
    .cont-list {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      li {
        position: relative;
        width: calc((100% - 60px) / 4);
        border: 1px solid #ddd;
        padding: 40px 20px 20px;
        box-sizing: border-box;
        &:before {
          content: "";
          position: absolute;
          top: 20px;
          right: 20px;
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background: #ee2c3c;
        }
        .tit {
          display: block;
          color: #101010;
          font-family: Montserrat, "Noto Sans KR", sans-serif;
          font-size: 20px;
          font-weight: 700;
          line-height: 30px;
        }
        .sub {
          margin-top: 2px;
          color: #101010;
          font-family: "Noto Sans KR", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
        .txt {
          margin-top: 15px;
          color: #666;
          font-family: "Noto Sans KR", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  }
  .bg-img {
    margin-top: 23px;
    img {
      width: 100%;
    }
  }
  .section {
    &.section-1 {
      margin-top: 306px;
    }
    &.section-2 {
      margin-top: 220px;
      .section-tit .puzzle-img {
        width: 284px;
      }
    }
    &.section-3 {
      margin-top: 360px;
      .section-img {
        margin-top: 150px;
      }
      &.open {
        height: auto;
        &:before {
          display: none;
        }
        .btn-open {
          &:after {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
      .section-tit .puzzle-img {
        width: 235px;
      }
      /* .btn-open {
      } */
      .section-img {
        position: relative;
        height: 456px;
        overflow: hidden;
        text-align: center;
        .btn-detail {
          display: inline-flex;
          align-content: center;
          justify-content: center;
          margin-top: 60px;
          border: 1px solid #ddd;
          border-radius: 40px;
          background: #fff;
          padding: 20px 40px;
          color: #1e1e1e;
          font-size: 18px;
          font-weight: 400;
          line-height: 150%;
          img {
            width: 24px;
            height: 24px;
            margin-left: 5px;
          }
        }
        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 140px;
          z-index: 1;
          background: url(../../assets/images/consulting/gradient.png) center center / cover
            no-repeat;
        }
        &.open {
          height: auto;
          padding-bottom: 120px;
          .btn-open {
            bottom: 40px;
            &:after {
              transform: translateY(-50%) rotate(180deg);
            }
          }
          &:before {
            display: none;
          }
        }
      }
    }
    &.section-4 {
      margin-top: 360px;
    }
    &.section-5 {
      margin-top: 180px;
    }
    &.section-6 {
      margin-top: 280px;
    }
    .section-txt {
      color: #ee2c3c;
      font-size: 24px;
      font-weight: 700;
      line-height: 34px;
    }
    .section-tit {
      margin-top: 20px;
      color: #101010;
      font-size: 48px;
      font-weight: 700;
      line-height: 145%;
      span {
        position: relative;
        color: #ee2c3c;
      }
      .puzzle-img {
        margin-left: 10px;
        width: 111px;
        height: 40px;
      }
    }
    .section-desc {
      margin-top: 30px;
      color: #101010;
      font-size: 24px;
      font-weight: 400;
      line-height: 34px;
    }
    .section-img {
      margin-top: 90px;
    }
  }
  .lottie-cont {
    position: relative;
    width: 100%;
    margin-top: 150px;
    &.lottie01 {
      height: 850px;
      margin: 0;
      .lottie-container {
        height: 100%;
      }
    }
  }
  .swiper-section {
    width: 100vw;
    height: 100vh;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
      width: 60px;
      height: 60px;
      backdrop-filter: blur(200px);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      &:after {
        display: none !important;
      }
    }
    .swiper-button-prev {
      left: 82px !important;
    }
    .swiper-button-next {
      transform: rotate(180deg);
      right: 82px !important;
    }
    .swiper-slide {
      display: flex;
      justify-content: center;
      will-change: clip-path;
    }
    .inner {
      column-gap: 120px;
      padding: 27.7vh 0 0 0;
      display: flex;
    }
    .logo {
      font-size: 0;
      text-indent: -9999px;
      width: 400px;
      height: 110px;
    }
    .swiper-container {
      width: 100%;
      height: 100%;

      .swiper-slide {
        display: flex;
        justify-content: center;
        will-change: clip-path;

        .inner {
          column-gap: 120px;
          padding: 27.7vh 0 0 0;
          display: flex;
          .logo {
            font-size: 0;
            text-indent: -9999px;
            width: 400px;
            height: 110px;
          }
          .right {
            width: 640px;
            .title {
              color: #fff;
              font-family: "Noto Sans KR", sans-serif;
              font-size: 48px;
              font-weight: 300;
              line-height: 130%;
              letter-spacing: -2.4px;
              strong {
                font-weight: 700;
                display: block;
              }
            }
            .sm-tit {
              margin-top: 72px;
              padding-top: 20px;
              color: #fff;
              font-family: Montserrat, "Noto Sans KR", sans-serif;
              font-size: 18px;
              font-weight: 700;
              line-height: 26px;
              letter-spacing: -0.9px;
              border-top: 1px solid rgba(255, 255, 255, 0.5);
            }
            .sm-txt {
              margin-top: 10px;
              color: #fff;
              font-family: "Noto Sans KR", sans-serif;
              font-size: 18px;
              font-weight: 400;
              line-height: 28px;
              letter-spacing: -0.9px;
              word-break: keep-all;
            }
          }
        }
      }
    }
    .swiper-text {
      opacity: 0;
    }
  }
  ::v-deep(.modal-content .modal-title span) {
    opacity: 0.5;
    font-weight: 400;
  }
  .puzzle-list {
    li {
      height: 500px;
    }
  }
</style>
